import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { ClimateContext } from '../contexts/ClimateContext';
import useNavigation from '../utils/useNavigation';
import ClimateTooltip from '../utils/tooltip/ClimateTooltip';
import ClimateModal from '../utils/modal/ClimateModal';
import StreamingServicesModal from '../utils/modal/StreamingServicesModal'; 
import SmallCard from '../utils/SmallCard';
import ClimateStrip from '../effects/ClimateStrip';
import ClimateStripUser from '../effects/ClimateStripUser';
import axios from 'axios';
import { streamingServices, cardsData } from './constants'; // adjust the path to where constants.js is located
import { backendUrl } from '../config';

import '../styles/Lambe.css';
import '../styles/SocialIcons.css';
import '../style.css';


const Home = () => {
  const { handleFanbaseClick } = useNavigation();  
  const { isAuthenticated } = useAuth();
  const { generalClimateData, userClimateData, isLoading, fetchUserClimateData } = useContext(ClimateContext);
  const [userBirthYear, setUserBirthYear] = useState('');
  const [lambeImage, setLambeImage] = useState('');
  const [showUserClimateModal, setShowUserClimateModal] = useState(false);
  const [isStreamingModalOpen, setIsStreamingModalOpen] = useState(false);
  


  useEffect(() => {
    // Fetch random cover image
      axios.get(`${backendUrl}/api/cover-image`)
          .then(response => {
              const randomImage = response.data.imagePath;
              document.getElementById("main-container").style.backgroundImage = `url(${randomImage})`;
          })
          .catch(error => console.log(error));
      
          // Fetch random lambe image
      axios.get(`${backendUrl}/api/lambe-image`)
      .then(response => {
          setLambeImage(response.data.imagePath);
      })
      .catch(error => console.log(error));
  }, []);



  const handleCardClick = (text) => {
    console.log(`Card clicked: ${text}`);    
    if (text === "Ouça") {
      setIsStreamingModalOpen(true);
    } else {
      // Handle other card clicks as needed
    }    
  };
  

  const handleBirthYearSubmit = async (event) => {
    event.preventDefault();
    await fetchUserClimateData(userBirthYear);
    setShowUserClimateModal(!isLoading);  
  };

  return (
    <div id="main-container">
      <div id="social-icons">
        <a href="https://www.facebook.com/yourpage"><i className="fab fa-facebook-f"></i></a>
        <a href="https://www.instagram.com/super.condutores"><i className="fab fa-instagram"></i></a>
        <a href="https://open.spotify.com/artist/6Ef0dXC6Nww5nEzqrT1OZN?si=zCA6AQ-cS7aseU-vWeG9SA"><i className="fab fa-spotify"></i></a>
        <a href="https://studio.youtube.com/channel/UC59-qLGntTrjuvcSCTuUYmw"><i className="fab fa-youtube"></i></a>
        <a href="https://www.discord.com/super.condutores"><i className="fab fa-discord"></i></a>
        <a href="https://twitter.com/patalanov"><i className="fab fa-twitter"></i></a>
        <a href="https://www.tiktok.com/@super_condutores"><i className="fab fa-tiktok"></i></a>
      </div>
      <nav id="main-nav">
        <ul>
          <li><Link to="/musica">Música</Link></li>
          <li><Link to="/videos">Vídeos</Link></li>
          <li><Link to="/fotos">Fotos</Link></li>
          <li><Link to="/shows">Shows</Link></li>
          <li><Link to="#" onClick={handleFanbaseClick}>Fan Zone</Link></li>  {/* Use handleFanbaseClick here */}
        </ul>
      </nav>

      <div id="climateStrip" data-tooltip-id="climateTooltip">
        {generalClimateData && <ClimateStrip data={generalClimateData} />}
        <div id="tooltip-trigger" style={{ position: 'absolute', bottom: '0', width: '100%', height: '50px', cursor: 'pointer' }}></div>
      </div>

      <ClimateTooltip
        userBirthYear={userBirthYear} 
        setUserBirthYear={setUserBirthYear} 
        onSubmit={handleBirthYearSubmit}  
      />

      <ClimateModal 
        isOpen={showUserClimateModal} 
        onRequestClose={() => setShowUserClimateModal(false)}
        >
        <h3 className="modal-header-text">Temperaturas Médias — {userBirthYear} a 2024</h3>
        {userClimateData && <ClimateStripUser data={userClimateData} />}
      </ClimateModal>

      <div className="small-cards-container-home">
        {cardsData.map((text, index) => (
          <SmallCard
            key={index}
            text={text}
            onClick={() => handleCardClick(text)}
          />
        ))}
      </div>

      <StreamingServicesModal
        isOpen={isStreamingModalOpen}
        onRequestClose={() => setIsStreamingModalOpen(false)}
        services={streamingServices}
      />

      {/* Render the lambe image */}
      {lambeImage && (
        <div className="lambe-image-container">
          <img src={lambeImage} alt="Lambe Image" className="lambe-image" />
        </div>
      )}

      <div id="contentDiv">
        {/* Placeholder for fanbase content */}
      </div>
    </div>
  );
};

export default Home;
